import PageMeta from '@components/PageMeta';
import useTranslation from '@helpers/useTranslation';
import DefaultTemplate from '@templates/DefaultTemplate';
import DownloadView from '@views/DownloadView';
import { PageProps } from 'gatsby';
import React, { FC } from 'react';

const DownloadIOSPage: FC<PageProps> = () => {
  const { t } = useTranslation('pages');

  return (
    <DefaultTemplate>
      <PageMeta
        description={t('pages:downloadPlatformDescription', { platform: 'iOS' })}
        title={t('pages:downloadPlatform', { platform: 'iOS' })}
      />
      <DownloadView platform="iOS" />
    </DefaultTemplate>
  );
};

export default DownloadIOSPage;
